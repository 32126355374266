import axios from "axios";
import React, { useEffect, useState } from "react";
import { getLocalTime } from "../../utils/dateTimeByTZ";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Props = {};

const Documents = (props: Props) => {
  const BASE_URL = process.env.REACT_APP_API_KEY;
  const [files, setFiles] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [disable, setDisable] = useState(false);
  const [openDialogId, setOpenDialogId] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);

  const showToast = (message: string, type: "success" | "error") => {
    toast[type](message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  };

  const handleAddDoc = async () => {
    window.location.pathname = "/documents/add";
  };

  const fetchDocuments = async () => {
    setLoading(true);
    setFiles([]);
    const SystemNumber = localStorage.getItem("systemNumber");
    const token = localStorage.getItem("authtoken") || "";
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${BASE_URL}/document/getDocuments/${SystemNumber}?page=${page}`,
        config
      );
      setFiles(response.data.allDocuments);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [page]);

  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleOpenDialog = (id: string) => {
    setOpenDialogId(id);
  };

  const handleCloseDialog = () => {
    setOpenDialogId(null);
  };

  const handleDeleteDoc = async (item: any) => {
    const token = localStorage.getItem("authtoken") || "";
    setOpenDialogId(null);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const deleteResponse = await axios.delete(
        `${BASE_URL}/document/delete/${item}`,
        config
      );
      if (deleteResponse.data.success) {
        showToast(deleteResponse.data.message, "success");
        setOpenDialogId(null);
        fetchDocuments();
      } else {
        showToast("Failed to delete Document", "error");
      }
    } catch (error) {}
  };

  const documents = files.map((file: any, index: any) => ({
    id: file._id,
    fileName: file.originalFileName,
    type: file.mimetype,
    url: file.url,
    date: file.date,
  }));

  const handleDownload = (url: string, fileName: string) => {
    setDisable(true);

    const anchor = document.createElement("a");
    anchor.href = `${url}`;
    // anchor.title = fileName;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    setDisable(false);
  };

  return (
    <div>
      <ToastContainer />
      <div className="card-list">
        <h2 style={{ fontSize: "28px", fontWeight: "bold" }}>Documents</h2>
        <button
          className="blue"
          style={{ float: "right" }}
          onClick={handleAddDoc}
        >
          Add Document
        </button>
      </div>
      <hr></hr>

      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress sx={{ color: "#0e1937 !important" }} />
        </div>
      ) : (
        <div>
          {documents.length === 0 ? (
            <div style={{ textAlign: "center" }}>
              <b>No Documents Uploaded</b>
            </div>
          ) : (
            <div className="list-container">
              {documents.map((item) => (
                <div
                  className="card"
                  key={item?.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                >
                  <div>
                    <h4
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {item.fileName}
                    </h4>
                    <p>{getLocalTime(item.date)}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <button
                      className="blue"
                      style={{ alignSelf: "center" }}
                      onClick={() => handleDownload(item.url, item.fileName)}
                      disabled={disable}
                    >
                      Download
                    </button>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="contained"
                        className="muiButton"
                        onClick={() => handleOpenDialog(item.id)}
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          marginLeft: "10px",
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                      <Dialog
                        open={openDialogId === item.id}
                        onClose={handleCloseDialog}
                        sx={{
                          "& .MuiBackdrop-root": {
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                          },
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Delete Confirmation"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete {item.fileName}?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            // variant="contained"
                            onClick={handleCloseDialog}
                            sx={{ color: "#0e1937" }}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => handleDeleteDoc(item.id)}
                            sx={{ color: "#0e1937" }}
                          >
                            Yes
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {totalPages > 1 ? (
        <div className="pagination">
          <Button
            onClick={handlePrevious}
            disabled={page === 1}
            sx={{ color: "#0e1937" }}
          >
            Prev
          </Button>
          <Button
            onClick={handleNext}
            disabled={page === totalPages}
            sx={{ color: "#0e1937" }}
          >
            Next
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default Documents;
