import { useState, useEffect } from "react";
import { Form, Formio } from "react-formio";
import "./stylesF.css";
import axios from "axios";
import FormBuilderComponent from "./FormBuilderComponent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  TextField,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { getLocalTime } from "../../utils/dateTimeByTZ";
import { coopsData } from "../../utils/coopsData";
import CircularProgress from "@mui/material/CircularProgress";

interface Data {
  name: string;
  type: string;
  isdraft: boolean;
  values: any[];
  all: boolean;
  systemNumber: any[];
}
interface patchData {
  istrash: boolean;
  isdraft: boolean;
  name: any;
  type: any;
  all: any;
  systemNumber: any;
}

interface SelectedData {
  formName?: string;
  type?: string;
  all?: boolean;
  systemNumber?: number;
  id?: number;
}

const formOptions = [
  "Job Briefing",
  "Jobsite Observation",
  "Vehicle/Trailer Inspection",
  "Periodic Inspections",
  "Equipment Inspections",
  "General Forms",
];

const PublishedForm = ({ draft }: { draft: boolean }) => {
  const BASE_URL = process.env.REACT_APP_API_KEY;
  const [formsData, setFormsData] = useState([]);
  const [openform, setOpenform] = useState(false);
  const [editform, setEditform] = useState(false);
  const [formData, setFormData] = useState({ components: [] });
  const [formName, setFormName] = useState("");
  const [formType, setFormType] = useState("");
  const [currentFormId, setCurrentFormId] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedData, setSelectedData] = useState<Partial<SelectedData>>({});
  const [coopList, setCoopList] = useState([]);
  const [coopData, setCoopData] = useState({ systemList: [] });
  const [all, setAll] = useState(true);
  const [systemNumbers, setSystemNumbers] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleDeleteClick = (item: any) => {
    setSelectedData(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const fetchFormsData = async () => {
    setLoading(true);
    const token = localStorage.getItem("authtoken") || "";
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const systemNumber = localStorage.getItem("systemNumber");
      const publishForms = `${BASE_URL}/form/getForms/${systemNumber}?page=${page}&isdraft=${draft}`;
      const draftForms = `${publishForms}&istrash=${false}`;
      const response = await axios.get(
        draft ? draftForms : publishForms,
        config
      );
      setTotalPages(response.data.totalPages);

      const filteredForms = response.data.allForms.filter(
        (item: { isdraft: any; istrash: any }) => {
          return draft === false
            ? !item.isdraft && !item.istrash
            : item.isdraft && !item.istrash;
        }
      );
      setFormsData(filteredForms);
    } catch (error) {
      console.error("Error fetching forms:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCoops = async () => {
      const coopData = await coopsData();
      setCoopData(coopData);
    };
    fetchCoops();
  }, []);

  useEffect(() => {
    setOpenform(false);
    setEditform(false);
    setFormsData([]);
    fetchFormsData();
  }, [draft, page]);

  useEffect(() => {
    if (openform || editform) {
      window.scrollTo(0, 0);
    }
  }, [openform, editform]);

  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const dataF = formsData.map((form: any, index: any) => ({
    id: form._id,
    type: form.type,
    formName: form.name,
    date: form.date,
    all: form.all,
    systemNumber: form.systemNumber,
  }));

  const showToast: any = (message: string, type: "success" | "error") => {
    toast[type](message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  };

  async function formFun(formId: number, action: "open" | "edit" | "delete") {
    setLoading(true);
    const token = localStorage.getItem("authtoken") || "";
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const id = formId;

      if (action === "open" || action === "edit") {
        const formResponse = await axios.get(`${BASE_URL}/form/${id}`, config);

        setFormData(formResponse?.data.form.values);
        setFormName(formResponse?.data.form.name);
        setFormType(formResponse?.data.form.type);
        setSystemNumbers(formResponse?.data.form.systemNumber);
        setCurrentFormId(id);
        if (action === "open") {
          setOpenform(true);
          setEditform(false);
        } else if (action === "edit") {
          setOpenform(false);
          setEditform(true);
        }
      } else if (action === "delete") {
        setOpenDialog(false);

        const data: patchData = {
          istrash: true,
          isdraft: true,
          name: selectedData.formName ?? "", // Use ?? to provide a fallback value
          type: selectedData.type ?? "",
          all: selectedData.all ?? false,
          systemNumber: selectedData.systemNumber ?? 0,
        };

        const deleteResponse = await axios.patch(
          `${BASE_URL}/form/${selectedData.id}`,
          data,
          config
        );

        if (deleteResponse.data.success) {
          showToast("Form Moved to Trash successfully", "success");
          fetchFormsData();
        } else {
          showToast("Failed to delete form", "error");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setOpenform(false);
      setEditform(false);
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (value: any) => {
    const token = localStorage.getItem("authtoken") || "";
    try {
      if (formName === "") {
        showToast("Please enter the Form Name!", "error");
        return;
      } else if (formType === "") {
        showToast("Please Choose the Form Type!", "error");
        return;
      } else {
        const form = await Formio.createForm(
          document.getElementById("formio-result"),
          {
            components: formData,
          }
        );

        form.on("change", function (event: any) {
          console.log("form.schema", form.schema);
        });

        if (all) {
          setSystemNumbers([]);
        } else {
          setSystemNumbers(coopList);
        }

        const data: Data = {
          name: formName,
          type: formType,
          isdraft: value,
          values: form.schema.components,
          all: all,
          systemNumber: all ? [] : coopList,
        };

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const id = currentFormId;
        const formResponse = await axios.patch(
          `${BASE_URL}/form/${id}`,
          data,
          config
        );
        if (formResponse.status === 200) {
          showToast("Form Updated successfully!", "success");
          await fetchFormsData();
          setOpenform(false);
          setEditform(false);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      showToast("Error Updating Form. Please try again.", "error");
      setOpenform(false);
      setEditform(false);
    }
  };

  const form = {
    components: formData,
  };

  function closeform() {
    setOpenform(false);
    setEditform(false);
  }

  const handleFormSelect = (event: any, newValue: string | null) => {
    setFormType(newValue || "");
  };

  const redirectToCreateForm = () => {
    window.location.pathname = "/form/createform";
  };

  return (
    <>
      <ToastContainer />
      {!openform && !editform && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>
            <h2 style={{ fontSize: "28px", fontWeight: "bold" }}> FORMS </h2>
            <hr></hr>
            <>
              {loading ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress sx={{ color: "#0e1937 !important" }} />
                </div>
              ) : (
                <>
                  {dataF.length === 0 ? (
                    <div style={{ textAlign: "center" }}>
                      <p>Please, Click here to Create forms.</p>
                      <button
                        onClick={redirectToCreateForm}
                        style={{
                          backgroundColor: "#0e1937",
                          color: "white",
                        }}
                      >
                        Create Form
                      </button>
                    </div>
                  ) : (
                    <div>
                      <div className="list-container">
                        {dataF.map((item) => (
                          <div
                            className="card"
                            key={item.id}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              cursor: "pointer",
                            }}
                          >
                            <div>
                              <h2
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                {item.formName}
                              </h2>
                              <p>{getLocalTime(item.date)}</p>
                              <p>{item.type}</p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Button
                                variant="contained"
                                className="muiButton"
                                onClick={() => formFun(item.id, "open")}
                                style={{
                                  backgroundColor: "#0e1937",
                                }}
                              >
                                Open
                              </Button>
                              <br />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  className="muiButton"
                                  onClick={() => formFun(item.id, "edit")}
                                  style={{
                                    marginRight: "10px",
                                    backgroundColor: "#0e1937"
                                  }}
                                >
                                  <EditIcon />
                                </Button>
                                <Button
                                  variant="contained"
                                  className="muiButton"
                                  onClick={() => handleDeleteClick(item)}
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                                <Dialog
                                  open={openDialog}
                                  onClose={handleCloseDialog}
                                  sx={{
                                    "& .MuiBackdrop-root": {
                                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                                    },
                                  }}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle id="alert-dialog-title">
                                    {"Delete Confirmation"}
                                  </DialogTitle>
                                  <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                      Are you sure you want to delete?
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button
                                      variant="contained"
                                      onClick={handleCloseDialog}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={() => formFun(item.id, "delete")}
                                    >
                                      Yes
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      {totalPages > 1 && (
                        <div className="pagination">
                          <button
                            onClick={handlePrevious}
                            disabled={page === 1}
                          >
                            Prev
                          </button>
                          <button
                            onClick={handleNext}
                            disabled={page === totalPages}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          </div>
        </div>
      )}
      {openform && (
        <>
          <div style={{ paddingBottom: "20px" }}>
            <Button
              className="muiButton"
              onClick={() => {
                closeform();
              }}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
            <div style={{ textAlign: "center" }}>
              <TextField
                disabled
                type="text"
                value={formName}
                label="Enter Form Name"
                style={{ marginRight: "40px" }}
              />
              <TextField
                disabled
                value={formType}
                sx={{ width: 300 }}
                placeholder="Form type"
              />
            </div>
          </div>
          <Form form={form} />
        </>
      )}
      {editform && (
        <>
          <div style={{ paddingBottom: "20px" }}>
            <Button
              className="muiButton"
              sx={{ color: "#0e1937 !important" }}
              onClick={() => {
                closeform();
              }}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
            <div
              style={{
                paddingBottom: "3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                type="text"
                value={formName}
                onChange={(e) => {
                  setFormName(e.target.value);
                }}
                placeholder="Enter Form Name"
                label="Enter Form Name"
                className="formNameInput"
                style={{ marginRight: "40px" }}
              />
              <Autocomplete
                options={formOptions}
                value={formType}
                sx={{ width: 300 }}
                onChange={handleFormSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Form Type"
                    className="formNameInput"
                  />
                )}
              />
            </div>
          </div>
          <FormBuilderComponent
            formData={form}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
            coopData={coopData}
            setCoopList={setCoopList}
            setAll={setAll}
            systemNumbers={systemNumbers}
          />
        </>
      )}
    </>
  );
};

export default PublishedForm;
