import React from "react";
import { Navigate } from "react-router-dom";

interface RoleBasedRouteProps {
  children: JSX.Element;
  roles: string[];
  userRole: string;
  redirectPath?: string;
}

const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({
  children,
  roles,
  userRole,
  redirectPath = "/login",
}) => {
  if (!userRole || !roles.includes(userRole)) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};

export default RoleBasedRoute;
