import React, { useEffect, useState } from "react";
import "./inbox_table.css";
import axios from "axios";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { getLocalTime } from "../../utils/dateTimeByTZ";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface FeedbackData {
  _id: string;
  username: string;
  email: string;
  subject: string;
  date: string;
}

interface SingleFeedback {
  _id: string;
  name: any;
  username: any;
  email: string;
  subject: string;
  text: string;
  date: string;
}

const Inbox = () => {
  const BASE_URL = process.env.REACT_APP_API_KEY;
  const [feedbackData, setFeedbackData] = useState<FeedbackData[]>([]);
  const [singleFeedback, setSingleFeedback] = useState<SingleFeedback | null>(
    null
  );
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [loading, setLoading] = useState(false);

  const showToast = (message: string, type: "success" | "error") => {
    toast[type](message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  };

  const getSubmittedForms = async () => {
    setLoading(true);
    setFeedbackData([]);
    const systemNumber = localStorage.getItem("systemNumber");
    const token = localStorage.getItem("authtoken") || "";
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(
        `${BASE_URL}/feedbackform/getFeedbackForms/${page}?systemNumber=${systemNumber}`,
        config
      );
      setFeedbackData(response.data.results);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching forms:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubmittedForms();
  }, [page, BASE_URL]);

  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleFeedback = async (id: string) => {
    const token = localStorage.getItem("authtoken") || "";
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${BASE_URL}/feedbackform/${id}`,
        config
      );
      setSingleFeedback(response?.data?.form);
      setOpenDialog(true);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleDeleteConfirmFeedback = async (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    event.stopPropagation();
    setDeleteDialog(true);
    setDeleteId(id);
  };

  const handleDeleteFeedback = async (id: string) => {
    const token = localStorage.getItem("authtoken") || "";
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.delete(
        `${BASE_URL}/feedbackform/${id}`,
        config
      );
      setDeleteDialog(false);
      showToast("Feedback Deleted successfully!", "success");
      await getSubmittedForms();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteDialog(false);
  };

  return (
    <div>
      <ToastContainer />
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>User Name</th>
              <th>Email</th>
              <th>Subject</th>
              <th>Date</th>
              <th>Option</th>
            </tr>
          </thead>
          {feedbackData.length > 0 ? (
            <tbody>
              {feedbackData.map((feedback) => (
                <tr
                  key={feedback?._id}
                  onClick={() => handleFeedback(feedback?._id)}
                >
                  <td>{feedback?.username}</td>
                  <td>{feedback?.email}</td>
                  <td>{feedback?.subject}</td>
                  <td>{getLocalTime(feedback?.date)}</td>
                  <td onClick={(event) => event.stopPropagation()}>
                    <button
                      className="blue"
                      onClick={(event) =>
                        handleDeleteConfirmFeedback(event, feedback._id)
                      }
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={5} style={{ textAlign: "center" }}>
                  {loading ? (
                    <CircularProgress sx={{ color: "#0e1937 !important" }} />
                  ) : (
                    "No feedback submitted."
                  )}
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {totalPages > 1 && (
        <div className="pagination">
          <Button
            onClick={handlePrevious}
            disabled={page === 1}
            className="footer-btn"
            style={{ cursor: page === 1 ? "not-allowed" : "pointer" }}
          >
            Prev
          </Button>
          <Button
            onClick={handleNext}
            disabled={page === totalPages}
            className="footer-btn"
          >
            Next
          </Button>
        </div>
      )}
      {singleFeedback && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="email-dialog-title"
          aria-describedby="email-dialog-description"
          sx={{
            "& .MuiDialog-paper": {
              width: "40%",
              maxWidth: "70%",
              maxHeight: "70%",
            },
          }}
        >
          <DialogTitle id="email-dialog-title">Feedback Details</DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="email-dialog-description">
              <p className="text">
                <strong>From:</strong> {singleFeedback?.name} (
                {singleFeedback?.username})
              </p>
              <p className="text">
                <strong>Email:</strong>{" "}
                {singleFeedback?.email || "No email provided"}
              </p>
              <p className="text">
                <strong>Subject:</strong> {singleFeedback?.subject}
              </p>
              <p className="text">
                <strong>Message:</strong>
              </p>
              <p className="text">{singleFeedback?.text}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      {deleteDialog && (
        <Dialog
          open={deleteDialog}
          onClose={handleCloseDialog}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button onClick={() => handleDeleteFeedback(deleteId)}>Yes</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default Inbox;
