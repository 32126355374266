import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const SessionChecker = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const logoutChannel = new BroadcastChannel("logoutChannel");

    const checkSession = () => {

      if (location.pathname !== "/login" && localStorage.getItem("isLoggedIn") !== "true") {
        navigate("/login");
        logoutChannel.postMessage("logout");
      }

      const expiryDateTime = localStorage.getItem("LogoutTime") || "0";
      const now = Math.floor(Date.now() / 1000);

      if (parseInt(expiryDateTime) < now) {
        localStorage.clear();
        sessionStorage.clear();
        logoutChannel.postMessage("logout");
      }
    };

    logoutChannel.onmessage = (message) => {
      if (message.data === "logout") {
        navigate("/login");
        localStorage.clear();
        sessionStorage.clear();
      }
    };

    const intervalId = setInterval(checkSession, 1000);
    return () => {
      clearInterval(intervalId);
      logoutChannel.close();
    };
    
  }, [navigate, location]);

  return null; // This component does not render anything
};

export default SessionChecker;
