import React, { useEffect, useState } from "react";
import { FormBuilder as FormBuilderIo } from "@formio/react";
import { Formio } from "react-formio";
import "./stylesF.css";
import "react-form-builder2/dist/app.css";
import "formiojs/dist/formio.full.css";

interface FormBuilderProps {
  formData: any;
  setFormData: (data: any) => void;
  handleSubmit: (value: any) => void;
  coopData: any;
  setCoopList: any;
  setAll: any;
  systemNumbers: any;
}

const FormBuilderComponent: React.FC<FormBuilderProps> = ({
  formData,
  setFormData,
  handleSubmit,
  coopData,
  setCoopList,
  setAll,
  systemNumbers,
}) => {
  const [showCoopsModal, setShowCoopsModal] = useState(false);
  const [selectedCoopKeys, setSelectedCoopKeys] = useState<string[]>(
    coopData?.systemList.map((coop: any) => coop?.systemNumber)
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [userSystemNum, setUserSystemNum] = useState("");

  useEffect(() => {
    setCoopList(selectedCoopKeys);
  }, [selectedCoopKeys]);

  useEffect(() => {
    if (systemNumbers.length === 0) {
      setSelectedCoopKeys(
        coopData?.systemList.map((coop: any) => coop.systemNumber)
      );
    } else {
      setSelectedCoopKeys(systemNumbers);
    }
  }, [coopData]);

  const closeCoopsModal = () => {
    setShowCoopsModal(false);
  };

  const openCoopsModal = () => {
    setShowCoopsModal(true);
  };

  useEffect(() => {
    const userCoopNum = localStorage.getItem("systemNumber");
    if (userCoopNum) setUserSystemNum(userCoopNum);
  }, []);

  const previewForm = () => {
    const previewContainer = document.createElement("div");

    Formio.createForm(previewContainer, {
      components: formData.components,
    }).then((form: any) => {
      const popupWidth = 700;
      const popupHeight = 500;
      const leftPosition = window.innerWidth / 2 - popupWidth / 2;
      const topPosition = window.innerHeight / 2 - popupHeight / 2;
      const popupOptions = `width=${popupWidth},height=${popupHeight},left=${leftPosition},top=${topPosition},scrollbars=yes,resizable=yes`;

      const newTab = window.open("", "_blank", popupOptions) as Window;
      if (newTab) {
        const styleContainer = newTab.document.createElement("div");
        styleContainer.style.padding = "20px";
        styleContainer.appendChild(previewContainer);
        newTab.document.body.appendChild(styleContainer);

        const cssFormio = newTab.document.createElement("link");
        cssFormio.href = "https://cdn.form.io/formiojs/formio.full.min.css";
        cssFormio.rel = "stylesheet";
        newTab.document.head.appendChild(cssFormio);

        const cssBootstrap = newTab.document.createElement("link");
        cssBootstrap.href =
          "https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css";
        cssBootstrap.rel = "stylesheet";
        newTab.document.head.appendChild(cssBootstrap);

        newTab.onbeforeunload = () => {
          if (previewContainer.parentNode) {
            previewContainer.parentNode.removeChild(previewContainer);
          }
        };
      } else {
        alert(
          "Failed to open the preview. Please allow pop-ups for this site."
        );
      }
    });
  };

  const handleCheckboxChange = (coopKey: string) => {
    // Prevent changing the checkbox state if the coopKey is "15063" and matches the userSystemNum
    if (coopKey === userSystemNum || coopKey === "15063") return;

    // Toggle the selected state for the coop key
    setSelectedCoopKeys((prevSelected) =>
      prevSelected.includes(coopKey)
        ? prevSelected.filter((key) => key !== coopKey)
        : [...prevSelected, coopKey]
    );
  };

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchEmpty = () => {
    setSearchTerm("");
  };

  const filteredCoopData = searchTerm
    ? coopData && coopData.systemList
      ? coopData.systemList
          .filter(
            (coop: any) =>
              coop.systemName
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              coop.systemNumber.toString().includes(searchTerm)
          )
          .sort(
            (a: any, b: any) =>
              +selectedCoopKeys.includes(b.systemNumber) -
              +selectedCoopKeys.includes(a.systemNumber)
          )
      : []
    : coopData && coopData.systemList
    ? coopData.systemList
        .slice()
        .sort(
          (a: any, b: any) =>
            +selectedCoopKeys.includes(b.systemNumber) -
            +selectedCoopKeys.includes(a.systemNumber)
        )
    : [];

  const handleAllCheckboxChange = () => {
    if (selectedCoopKeys.length === coopData.systemList.length) {
      // When deselecting all, keep the userSystemNum selected
      const newUserSystemNumSelected = userSystemNum ? ["15063",userSystemNum,] : [];
      setSelectedCoopKeys(newUserSystemNumSelected);
      setAll(false);
    } else {
      // When selecting all, include all coop keys plus the userSystemNum if not already included
      const allCoopKeys = coopData.systemList.map(
        (coop: any) => coop.systemNumber
      );
      if (userSystemNum && !allCoopKeys.includes(userSystemNum)) {
        allCoopKeys.push(userSystemNum);
      }
      setSelectedCoopKeys(allCoopKeys);
      setAll(true);
    }
  };

  const isAllSelected = selectedCoopKeys.length === coopData.systemList.length;

  return (
    <div>
      {showCoopsModal && (
        <div className="modal">
          <div className="modal-content">
            <div>
              <span className="close" onClick={closeCoopsModal}>
                &times;
              </span>
            </div>
            <div style={{ padding: "10px", textAlign: "left" }}>
              <input
                type="text"
                placeholder="Search by name or number..."
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ width: "50%", padding: "10px" }}
              />
              <button
                className="blue coopListBtn"
                onClick={handleSearchEmpty}
                style={{ margin: "10px" }}
              >
                Clear
              </button>
            </div>
            {selectedCoopKeys.length !== 0 ? (
              <div className="selectedCoopNumList">
                {selectedCoopKeys.map((coop, index) => (
                  <span className="selectedCoopNum" key={index}>
                    {coop}
                  </span>
                ))}
              </div>
            ) : null}
            <div
              className="modal-body"
              style={{ height: "570px", overflowY: "auto" }}
            >
              <ul
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: 0,
                }}
              >
                <li className="coopNumberList">
                  <label
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={isAllSelected}
                      onChange={handleAllCheckboxChange}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                    <span style={{ marginLeft: 5 }}>All</span>
                  </label>
                </li>
                {filteredCoopData.map((coop: any, index: any) => (
                  <li key={coop.systemNumber} className="coopNumberList">
                    <label
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={
                          selectedCoopKeys.includes(coop.systemNumber) ||
                          coop.systemNumber === userSystemNum ||
                          coop.systemNumber === "15063"
                        }
                        onChange={() => handleCheckboxChange(coop.systemNumber)}
                        style={{
                          cursor: "pointer",
                        }}
                        disabled={
                          coop.systemNumber === userSystemNum ||
                          coop.systemNumber === "15063"
                        }
                      />
                      <span
                        style={{ marginLeft: 5 }}
                      >{`${coop.systemName}-(${coop.systemNumber})`}</span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ flex: 1 }}>
          <div className="right" style={{ margin: "10px" }}>
            <button className="blue coopListBtn" onClick={openCoopsModal}>
              Coops List
            </button>
          </div>
          <FormBuilderIo
            form={formData}
            options={{
              builder: {
                advanced: {
                  components: {
                    tags: false,
                    day: false,
                    time: false,
                    currency: false,
                    survey: false,
                  },
                },
                data: {
                  components: {
                    hidden: false,
                    tree: false,
                    editgrid: false,
                    datagrid: false,
                    datamap: false,
                  },
                },
                premium: {
                  components: {
                    recaptcha: false,
                    resource: false,
                    nestedform: false,
                    custom: false,
                  },
                },
              },
              alwaysConfirmComponentRemoval: true,
            }}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <div style={{ marginRight: "30px" }}>
          <button className="blue" onClick={previewForm}>
            Preview
          </button>
        </div>
        <div style={{ marginRight: "30px" }}>
          <button className="blue" onClick={() => handleSubmit(true)}>
            Save Draft
          </button>
        </div>
        <button className="blue" onClick={() => handleSubmit(false)}>
          Publish
        </button>
      </div>
      <div style={{ display: "none" }}>
        <div id="formio-result" />
      </div>
    </div>
  );
};

export default FormBuilderComponent;
