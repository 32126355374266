import { useEffect, useState } from "react";
import { coopsData } from "../../utils/coopsData";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import axios from "axios";

interface Data {
  name: string;
}

const AddDocument = () => {
  const BASE_URL = process.env.REACT_APP_API_KEY;
  const [coopData, setCoopData] = useState({ systemList: [] });
  const [showCoopsModal, setShowCoopsModal] = useState(false);
  const [selectedCoopKeys, setSelectedCoopKeys] = useState<string[]>(
    coopData?.systemList?.map((coop: any) => coop?.systemNumber)
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [userSystemNum, setUserSystemNum] = useState("");
  const [files, setFiles] = useState<Data[]>([]);
  const [all, setAll] = useState(true);
  const [username, setUsername] = useState("");
  const [disable, setDisable] = useState(false);
  const [sysNum, setSysNum] = useState(["15063"]);
  const [loggedInSysNum, setLoggedInSysNum] = useState("");

  const closeCoopsModal = () => {
    setShowCoopsModal(false);
  };

  const openCoopsModal = () => {
    setShowCoopsModal(true);
  };

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchEmpty = () => {
    setSearchTerm("");
  };

  const handleBack = () => {
    window.location.pathname = "/documents";
  };

  const handleFileChange = (event: any) => {
    // Assuming you want to keep previous files and add new ones
    setFiles([...files, ...event.target.files]);
  };

  const handleDrop = (event: any) => {
    event.preventDefault(); // Prevent default behavior

    const uploadedFiles = event.dataTransfer.files; // Access files

    if (uploadedFiles.length) {
      setFiles([...files, ...uploadedFiles]);
    }
  };

  const handleDragOver = (event: any) => {
    event.preventDefault(); // Necessary to allow drop
  };

  const handleRemove = (event: any) => {
    setFiles([]);
  };

  const filteredCoopData = searchTerm
    ? coopData && coopData.systemList
      ? coopData.systemList
          .filter(
            (coop: any) =>
              coop.systemName
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              coop.systemNumber.toString().includes(searchTerm)
          )
          .sort(
            (a: any, b: any) =>
              +selectedCoopKeys.includes(b.systemNumber) -
              +selectedCoopKeys.includes(a.systemNumber)
          )
      : []
    : coopData && coopData.systemList
    ? coopData.systemList
        .slice()
        .sort(
          (a: any, b: any) =>
            +selectedCoopKeys.includes(b.systemNumber) -
            +selectedCoopKeys.includes(a.systemNumber)
        )
    : [];

  const handleCheckboxChange = (coopKey: string) => {
    // Toggle the selected state for the coop key
    setSelectedCoopKeys((prevSelected) =>
      prevSelected.includes(coopKey)
        ? prevSelected.filter((key) => key !== coopKey)
        : [...prevSelected, coopKey]
    );
  };
  const isAllSelected = selectedCoopKeys.length === coopData.systemList.length;

  useEffect(() => {
    const username = localStorage.getItem("userName");
    setUsername(username || "");
  }, []);

  useEffect(() => {
    const systemNumber = localStorage.getItem("systemNumber") || "";

    if (systemNumber === "15063") {
      const fetchCoops = async () => {
        const coopData = await coopsData();
        setCoopData(coopData);

        // Automatically select all coop keys when data is fetched
        const allCoopKeys = coopData.systemList.map(
          (coop: any) => coop.systemNumber
        );
        setSelectedCoopKeys(allCoopKeys);
      };
      fetchCoops();
    } else {
      setSysNum((prevSysNum) => {
        // Check if systemNumber already exists in sysNum
        if (!prevSysNum.includes(systemNumber)) {
          return [...prevSysNum, systemNumber];
        }
        return prevSysNum; // If it exists, return the previous state unchanged
      });
      setAll(false);
    }
    setLoggedInSysNum(systemNumber);
  }, []);

  const handleAllCheckboxChange = () => {
    if (selectedCoopKeys.length === coopData.systemList.length) {
      // When deselecting all, keep the userSystemNum selected
      const newUserSystemNumSelected = userSystemNum ? [userSystemNum] : [];
      setSelectedCoopKeys(newUserSystemNumSelected);
      setAll(false);
    } else {
      // When selecting all, include all coop keys plus the userSystemNum if not already included
      const allCoopKeys = coopData.systemList.map(
        (coop: any) => coop.systemNumber
      );
      if (userSystemNum && !allCoopKeys.includes(userSystemNum)) {
        allCoopKeys.push(userSystemNum);
      }
      setSelectedCoopKeys(allCoopKeys);
      setAll(true);
    }
  };

  const handleSubmit = async () => {
    setDisable(true);
    const token = localStorage.getItem("authtoken") || "";
    const formData = new FormData();
    formData.append("username", username);
    formData.append("all", JSON.stringify(all));

    let systemNumber: any = [];

    if (loggedInSysNum === "15063") {
      systemNumber = all
        ? JSON.stringify([])
        : JSON.stringify(selectedCoopKeys);
    } else {
      systemNumber = JSON.stringify(sysNum);
    }

    formData.append("systemNumber", systemNumber);

    files.forEach((file) => {
      if (file instanceof Blob) {
        formData.append("file", file, file.name);
      }
    });

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${BASE_URL}/document/upload`,
        formData,
        config
      );

      if (response.status === 201) {
        setDisable(false);
        window.location.pathname = "/documents";
      } else {
        console.error("Submission failed", response.statusText);
        setDisable(false);
      }
    } catch (error) {
      console.error("Error submitting form", error);
      setDisable(false);
    }
  };

  return (
    <div>
      <div>
        {" "}
        {showCoopsModal && (
          <div className="modal">
            <div className="modal-content">
              <div>
                <span className="close" onClick={closeCoopsModal}>
                  &times;
                </span>
              </div>
              <div style={{ padding: "10px", textAlign: "left" }}>
                <input
                  type="text"
                  placeholder="Search by name or number..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  style={{ width: "50%", padding: "10px" }}
                />
                <button
                  className="blue coopListBtn"
                  onClick={handleSearchEmpty}
                  style={{ margin: "10px" }}
                >
                  Clear
                </button>
              </div>
              {selectedCoopKeys.length !== 0 ? (
                <div className="selectedCoopNumList">
                  {selectedCoopKeys.map((coop, index) => (
                    <span className="selectedCoopNum" key={index}>
                      {coop}
                    </span>
                  ))}
                </div>
              ) : null}
              <div
                className="modal-body"
                style={{ height: "570px", overflowY: "auto" }}
              >
                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    padding: 0,
                  }}
                >
                  <li className="coopNumberList">
                    <label
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isAllSelected}
                        onChange={handleAllCheckboxChange}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                      <span style={{ marginLeft: 5 }}>All</span>
                    </label>
                  </li>
                  {filteredCoopData.map((coop: any, index: any) => (
                    <li key={coop.systemNumber} className="coopNumberList">
                      <label
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={
                            selectedCoopKeys.includes(coop.systemNumber) ||
                            coop.systemNumber === userSystemNum
                          }
                          onChange={() =>
                            handleCheckboxChange(coop.systemNumber)
                          }
                          style={{
                            cursor: "pointer",
                          }}
                          disabled={coop.systemNumber === userSystemNum}
                        />
                        <span
                          style={{
                            marginLeft: 5,
                          }}
                        >{`${coop.systemName}-(${coop.systemNumber})`}</span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="card-list">
        <Button
          onClick={() => {
            handleBack();
          }}
          style={{
            float: "right",
            cursor: disable ? "not-allowed" : "pointer",
          }}
          sx={{ color: "#0e1937 !important" }}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        {loggedInSysNum === "15063" && (
          <button
            className="blue"
            onClick={openCoopsModal}
            style={{
              float: "right",
              cursor: disable ? "not-allowed" : "pointer",
            }}
          >
            Coop List
          </button>
        )}
      </div>

      <hr />
      {files.length === 0 ? (
        <div
          className="upload-area"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          style={{
            border: "2px dashed #ccc",
            padding: "20%",
            textAlign: "center",
            cursor: "pointer",
            marginTop: "20px",
          }}
        >
          Drag and drop files here, or click to select files
          <input
            type="file"
            onChange={handleFileChange}
            accept="application/pdf"
            style={{ display: "none" }}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <IconButton color="primary" component="span">
              <AddIcon sx={{ color: "#0e1937 !important" }} />
            </IconButton>
          </label>
        </div>
      ) : (
        <div>
          <div>
            {files.length > 0 && (
              <div>
                <h4>Uploaded File</h4>
                <div>
                  <div
                    style={{
                      listStyleType: "none",
                      padding: 0,
                    }}
                  >
                    {files.map((file, index) => (
                      <li
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <IconButton
                          color="primary"
                          component="span"
                          style={{
                            marginLeft: "auto",
                          }}
                        >
                          <ArticleOutlinedIcon />
                        </IconButton>

                        <span>{file.name}</span>
                        <IconButton
                          onClick={() => handleRemove(file)}
                          color="primary"
                          component="span"
                          style={{
                            marginRight: "auto",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            <button
              disabled={disable}
              className="blue"
              style={{
                float: "right",
                cursor: disable ? "not-allowed" : "pointer",
              }}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddDocument;
