import { useEffect } from "react";
import img from "../../assets/images/comingsoon.jpg";
import "../component/stylesF.css";
import { useNavigate } from "react-router-dom";

type Props = {};

const Reports = (props: Props) => {

  return (
    <div>
      <img src={img} alt="Coming Soon" className="responsive-image" />
    </div>
  );
};

export default Reports;
