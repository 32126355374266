import { Drawer, Grid, List, Stack, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import title_logo from "../../assets/images/logo.png";
import { useEffect, useState } from "react";
import { Modal, Button, Fade } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import RouteType from "../../routes/config";

interface SidebarProps {
  userRole: string;
}

const Sidebar: React.FC<SidebarProps> = ({ userRole }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const capitalizedUsername =
    username.charAt(0).toUpperCase() + username.slice(1);

  useEffect(() => {
    async function fetchData() {
      const userName = localStorage.getItem("userName");
      setUsername(userName || "");
      const email = localStorage.getItem("email");
      setEmail(email || "");
    }
    fetchData();
  }, []);

  const handleLogout = () => {
    setLogoutModalOpen(true);
  };

  const handleLogoutConfirm = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.clear();
    sessionStorage.clear();

    const logoutChannel = new BroadcastChannel("logoutChannel");
    logoutChannel.postMessage("logout");
    logoutChannel.close();

    navigate("/login");
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: sizeConfigs.sidebar.width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: sizeConfigs.sidebar.width,
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color,
        },
      }}
    >
      <List disablePadding>
        <Grid sx={{ marginBottom: "60px" }}>
          <Toolbar sx={{ marginBottom: "60px" }}>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="center"
            >
              <img
                src={title_logo}
                style={{
                  width: "100px",
                  height: "100px",
                  backgroundColor: "white",
                  padding: "9px",
                  borderRadius: "11%",
                  marginTop: "37%",
                  // marginBottom:"41%"
                }}
                alt=""
              />
            </Stack>
          </Toolbar>

          <Typography style={{ textAlign: "center" }}>
            Hello,{" "}
            <Typography
              className="username"
              display="inline"
              onClick={handleLogout}
            >
              {capitalizedUsername}
              <span role="img" aria-label="logout">
                {" "}
                <PowerSettingsNewIcon />
              </span>
            </Typography>
          </Typography>
        </Grid>
        {appRoutes(userRole).map((route: RouteType, index: number) =>
          route.sidebarProps ? (
            route.child ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              <SidebarItem item={route} key={index} />
            )
          ) : null
        )}
      </List>

      <Modal
        open={logoutModalOpen}
        onClose={() => setLogoutModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={logoutModalOpen}>
          <div
            className="logoutmodal"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              minHeight: "15%",
              minWidth: "23%",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 2 }}
            >
              Are you sure you want to log out?
            </Typography>
            <Grid container justifyContent="space-between">
              <Button
                variant="text"
                onClick={handleLogoutConfirm}
                sx={{ mr: 1, color: "#0e1937 !important" }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={() => setLogoutModalOpen(false)}
                sx={{ ml: 1, backgroundColor: "#0e1937 !important" }}
              >
                No
              </Button>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </Drawer>
  );
};

export default Sidebar;
